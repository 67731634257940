import React, { Component } from "react";
// import trackingService from "../../services/trackingService";
import GenerateArrayStrings from "../../components/GenerateArrayStrings";
import RelevanceHighlight from "../../components/RelevanceHighlight";
import moment from 'moment';
import { uniq } from 'underscore'


class RawValuesDocumentData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      documentIndex: [],
      collapsed: true,
      appliedFilters: [],
      searchKeyword: "",
      pageSize: "",
      pageNumber: "",
      score: 0,
      wwid: JSON.parse(localStorage.getItem('wwid'))
    };
  }
  componentDidMount() {
    this.setState({
      item: this.props.item,
      documentIndex: this.props.documentIndex,
      appliedFilters: this.props.appliedFilters,
      searchKeyword: this.props.searchKeyword,
      pageSize: this.props.pageSize,
      pageNumber: this.props.pageNumber,
      score: this.props.score,
    });
    // this.wwid = JSON.parse(localStorage.getItem('wwid'));
    // if ((window.location.origin === 'https://predev-ims.jnj.com' ||
    //   window.location.origin === 'http://localhost:3000') && this.wwid === "643039898") {
    //   const script = document.createElement("script");
    //   script.src = "https://content.readcube.com/injectable-button-assets/readcube-button.min.js";
    //   script.async = true;
    //   document.body.appendChild(script);
    // }
  }
  onClickTitle = () => {
    if (this.state.item.downloadUrl) {
      let url = this.state.item.downloadUrl;
      window.open(url);
    }
  };
  handleToggleSelect = (event) => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    //console.log(this.state.collapsed);
  };
  /* indexData = event => {
    var title = [event.target.getAttribute("data-title")];
    var products = [event.target.getAttribute("data-products")];
    var docnumber = [event.target.getAttribute("data-docnumber")];
    var docversion = [event.target.getAttribute("data-version")];
    var contentsource = [event.target.getAttribute("data-contentsource")];
    var docID = [event.target.getAttribute("data-docid")];
    var docType = [event.target.getAttribute("data-doctype")];
    var docUrl = [event.target.getAttribute("data-docurl")];
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      "View OR Download Document",
      "Direct",
      window.location.href,
      this.state.searchKeyword,
      this.state.appliedFilters,
      title,
      docversion,
      docnumber,
      docID,
      docUrl,
      products,
      docType,
      "View Document",
      "",
      "",
      contentsource,
      this.props.pageNumber,
      this.props.pageSize
    ); 
  };*/
  render() {
    var approvedDate;
    if (this.state.item.approvedDate) {
      approvedDate = moment(this.state.item.approvedDate).format("D-MMM-YYYY");
    }
    var modifiedDate;
    if (this.state.item.modifiedDate) {
      modifiedDate = moment(this.state.item.modifiedDate).format("D-MMM-YYYY");
    } else {
      modifiedDate = moment(this.state.item.lastUpdated).format("D-MMM-YYYY");
    }

    return (
      <div style={{ float: "left" }}>
        <span
          style={{ color: "#1076bc", fontSize: "14px", fontWeight: "bold" }}
        >
          {this.state.documentIndex}.{" "}
        </span>
        <span>
          <div
            id="docdata"
            className="raw-values-data-title  raw-values-doc-title"
            onClick={this.onClickTitle}
            data-title={this.state.item.docTitle}
            data-products={this.state.item.tempJNJDrugs}
            data-docnumber={this.state.item.domain}
            data-contentsource={this.state.item.domain}
            data-docid={this.state.item.searchID}
            data-doctype={this.state.item.docType}
          >
            {this.state.item.docTitle}
          </div>
          <div className="raw-values-data-title">
            {this.state.item.majorTopicsSynonyms && (
              <RelevanceHighlight
                item={this.state.item}
                searchKeyword={this.props.searchKeyword}
              />
            )}
            {!this.state.collapsed ? (
              <div
                title="Collapse Details"
                onClick={this.handleToggleSelect}
                style={{ cursor: "pointer" }}
                className="raw-values-expand-collapse"
              >
                Hide All
              </div>
            ) : (
              <div
                title="Expand Details"
                onClick={this.handleToggleSelect}
                style={{ cursor: "pointer" }}
                className="raw-values-expand-collapse"
              >
                Show All
              </div>
            )}
          </div>
        </span>
        {this.state.collapsed === false && (
          <div id={`docID-${this.state.documentIndex}`}>
            {((window.location.origin === 'https://predev-ims.jnj.com' ||
              window.location.origin === 'http://localhost:3000') && this.state.wwid === "643039898") && (
                <div className="PHM-MetaDataProperty">
                  <div style={{ display: "block" }}>
                    {this.state.item.pmID !== undefined &&
                      this.state.item.pmID.length > 0 &&
                      Array.isArray(this.state.item.pmID) ? (
                      <label>{GenerateArrayStrings(this.state.item.pmID)}</label>
                    ) : (<>
                      <span>-----------------------------</span>
                      <br />
                      <span class="__readcube-library-button" data-pmid={this.state.item.pmID}></span>
                      <br />
                      <span>-----------------------------</span>
                    </>
                    )}
                  </div>
                </div>
              )}
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Rank/Score:&nbsp;</label>
                </b>
                <label>{this.props.score}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Keywords:&nbsp;</label>
                </b>
                {this.state.item.keywords !== undefined &&
                  this.state.item.keywords.length > 0 &&
                  Array.isArray(this.state.item.keywords) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.keywords)}
                  </label>
                ) : (
                  <label>{this.state.item.keywords}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Extracted Keywords:&nbsp;</label>
                </b>
                {this.state.item.extractedKeywords !== undefined &&
                  this.state.item.extractedKeywords.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.extractedKeywords)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Protocol Number:&nbsp;</label>
                </b>
                {this.state.item.protocolIDs !== undefined &&
                  this.state.item.protocolIDs.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.protocolIDs)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Protocol Number Synonyms:&nbsp;</label>
                </b>
                {this.state.item.pidSynonyms !== undefined &&
                  this.state.item.pidSynonyms.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.pidSynonyms)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Clinical Trial Names (processed):&nbsp;</label>
                </b>
                {this.state.item.ctNames !== undefined &&
                  this.state.item.ctNames.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.ctNames)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Clinical Trial Names (source):&nbsp;</label>
                </b>
                {this.state.item.clinicalTrialName !== undefined &&
                  this.state.item.clinicalTrialName.length > 0 &&
                  Array.isArray(this.state.item.clinicalTrialName) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.clinicalTrialName)}
                  </label>
                ) : (
                  <label>{this.state.item.clinicalTrialName}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Created Date (Version Created Date):&nbsp;</label>
                </b>
                {this.state.item.createdDate && (
                  <label>
                    {moment(this.state.item.createdDate).format("D-MMM-YYYY")}
                  </label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Domain (Content Scope):&nbsp;</label>
                </b>

                <label>{this.state.item.domain}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>
                    Modified Date (Version Modified Date for VV and
                    RIMDocs):&nbsp;
                  </label>
                </b>
                {this.state.item.modifiedDate && <label>{modifiedDate}</label>}
                {/* <label>{modifiedDate}</label> */}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Year (IntCongressYear):&nbsp;</label>
                </b>
                <label>{this.state.item.year}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Classification:&nbsp;</label>
                </b>

                <label>{this.state.item.classification}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Lifecycle:&nbsp;</label>
                </b>

                <label>{this.state.item.lifecycle}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Primary Target Audience:&nbsp;</label>
                </b>

                <label>{this.state.item.pTargetAudience}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Distributed by:&nbsp;</label>
                </b>

                <label>{this.state.item.distributedBy}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Business Unit:&nbsp;</label>
                </b>

                <label>{this.state.item.businessUnit}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Line of Business:&nbsp;</label>
                </b>

                <label>{this.state.item.lineOfBusiness}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Document Item Description:&nbsp;</label>
                </b>

                <label>{this.state.item.docItemDescription}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Approved for Distribution Date:&nbsp;</label>
                </b>

                <label>{this.state.item.approvedDateString}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Expiration Date String:&nbsp;</label>
                </b>

                <label>{this.state.item.expirationDateString}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Type:&nbsp;</label>
                </b>

                <label>{this.state.item.type}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Subtype:&nbsp;</label>
                </b>

                <label>{this.state.item.subType}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Congress Name:&nbsp;</label>{" "}
                </b>
                {this.state.item.congressName !== undefined &&
                  this.state.item.congressName.length > 0 ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.congressName)}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>A_Status:&nbsp;</label>
                </b>

                <label>{this.state.item.a_status}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }} className="wrapDescription">
                <b>
                  <label>Description:&nbsp;</label>
                </b>

                <span style={{ wordWrap: "break-word" }}>
                  {this.state.item.description}
                </span>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Asset Type:&nbsp;</label>
                </b>

                <label>{this.state.item.assetType}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Audience:&nbsp;</label>{" "}
                </b>

                <label>{this.state.item.audience}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Usage Guidelines:&nbsp;</label>
                </b>

                <label>{this.state.item.usageGuidelines}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Last Updated:&nbsp;</label>
                </b>

                {this.state.item.lastUpdated && (
                  <label>
                    {moment(this.state.item.lastUpdated).format("D-MMM-YYYY")}
                  </label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Search ID (mpDocID in legacy system):&nbsp;</label>
                </b>

                <label>{this.state.item.searchID}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Doc ID (Object ID):&nbsp;</label>
                </b>

                <label>{this.state.item.docID}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Doc Type:&nbsp;</label>
                </b>

                <label>{this.state.item.docType}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Products:&nbsp;</label>
                </b>
                {this.state.item.tempJNJDrugs !== undefined &&
                  this.state.item.tempJNJDrugs.length > 0 ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.tempJNJDrugs)}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Authors:&nbsp;</label>
                </b>
                {this.state.item.authors !== undefined &&
                  this.state.item.authors.length > 0 &&
                  Array.isArray(this.state.item.authors) ? (
                  <label>{GenerateArrayStrings(this.state.item.authors)}</label>
                ) : (
                  <label>{this.state.item.authors}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Owner:&nbsp;</label>
                </b>
                <label>{this.state.item.owner}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Publication Status:&nbsp;</label>
                </b>
                <label>{this.state.item.publicationStatus}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Language:&nbsp;</label>
                </b>
                <label>{this.state.item.languages}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Content Source:&nbsp;</label>
                </b>
                <label>{this.state.item.csName}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Approved Date (Issued Date for RIMDocs):&nbsp;</label>{" "}
                </b>
                <label>{approvedDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Issued Date (for SRDs -iMK):&nbsp;</label>{" "}
                </b>
                <label>{this.state.item.issuedDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Expiration Date:&nbsp;</label>
                </b>
                <label>{this.state.item.expirationDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Presentation Type:&nbsp;</label>
                </b>
                <label>{this.state.item.presentationType}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Country:&nbsp;</label>
                </b>
                {this.state.item.countries !== undefined &&
                  this.state.item.countries.length > 0 && (
                    <>
                      {this.state.item.countries.includes("unitedStates") ? (
                        <label>United States</label>
                      ) : (
                        <label>{this.state.item.countries}</label>
                      )}
                    </>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Document Number:&nbsp;</label>
                </b>
                <label>{this.state.item.documentNumber}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Major Version Number:&nbsp;</label>
                </b>
                <label>{this.state.item.majorVersionNumber}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Minor Version Number:&nbsp;</label>
                </b>
                <label>{this.state.item.minorVersionNumber}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Status:&nbsp;</label>
                </b>
                <label>{this.state.item.status}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Phase of Study:&nbsp;</label>
                </b>
                {this.state.item.phaseOfStudy !== undefined &&
                  this.state.item.phaseOfStudy.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.phaseOfStudy)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Product Code:&nbsp;</label>
                </b>
                {this.state.item.product_code !== undefined &&
                  this.state.item.product_code.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.product_code)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Generic Name:&nbsp;</label>
                </b>
                {((this.state.item.docType &&
                  this.state.item.docType.includes("Investigator's Brochures")) &&
                  this.state.item.compoundName !== undefined &&
                  this.state.item.compoundName.length > 0) &&
                  (
                    <label>
                      {GenerateArrayStrings(uniq(this.state.item.compoundName.filter(x => x)))}
                    </label>
                  )}
                {(!this.state.item.docType || (this.state.item.docType && !this.state.item.docType.includes("Investigator's Brochures"))) &&
                  this.state.item.product_generic_name !== undefined &&
                  this.state.item.product_generic_name.length > 0 && (
                    <label>
                      {GenerateArrayStrings(uniq(this.state.item.product_generic_name.filter(x => x)))}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Generic Name (Aliases):&nbsp;</label>
                </b>
                {(this.state.item.docType && this.state.item.docType.includes("Investigator's Brochures")) &&
                  this.state.item.product_generic_name !== undefined &&
                  this.state.item.product_generic_name.length > 0 &&
                  (
                    <label>
                      {GenerateArrayStrings(uniq(this.state.item.product_generic_name.filter(x => x)))}
                    </label>
                  )}
                {((!this.state.item.docType || (this.state.item.docType && !this.state.item.docType.includes("Investigator's Brochures"))))
                  && this.state.item.product_generic_name_alias !== undefined &&
                  this.state.item.product_generic_name_alias.length > 0 && (
                    <label>
                      {GenerateArrayStrings(uniq(this.state.item.product_generic_name_alias.filter(x => x)))}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Component Name:&nbsp;</label>{" "}
                </b>

                <label>{this.state.item.jj_component_name}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Page Count:&nbsp;</label>
                </b>

                <label>{this.state.item.pageCount}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>System Version Number:&nbsp;</label>
                </b>

                <label>{this.state.item.r_version_label}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Artifact Short Name:&nbsp;</label>
                </b>

                <label>{this.state.item.jj_artifact_short_name}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>J&J Document Number (RIMDocs):&nbsp;</label>
                </b>

                <label>{this.state.item.jj_number}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Content Source ID:&nbsp;</label>
                </b>

                <label>{this.state.item.csID}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Connection ID:&nbsp;</label>
                </b>

                <label>{this.state.item.connID}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Extension:&nbsp;</label>
                </b>

                <label>{this.state.item.fileExtension}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>PubMed ID:&nbsp;</label>
                </b>

                {this.state.item.pmID !== undefined &&
                  this.state.item.pmID.length > 0 &&
                  Array.isArray(this.state.item.pmID) ? (
                  <label>{GenerateArrayStrings(this.state.item.pmID)}</label>
                ) : (
                  <label>{this.state.item.pmID}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <b>
                <label>Detail Description:&nbsp;</label>
              </b>

              <div className="result-Description">
                <div
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    width: "120%",
                  }}
                >
                  {this.state.item.detailDescription}
                </div>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Sponsor:&nbsp;</label>
                </b>

                <label>{this.state.item.leadSponsor}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Last Verified:&nbsp;</label>
                </b>

                <label>{this.state.item.lastVerified}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Intervention:&nbsp;</label>
                </b>

                <label>{this.state.item.intervention}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Condition:&nbsp;</label>
                </b>

                <label>{this.state.item.condition}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Overall Status:&nbsp;</label>
                </b>

                <label>{this.state.item.overallStatus}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Start Date:&nbsp;</label>
                </b>

                <label>{this.state.item.startDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>End Date:&nbsp;</label>
                </b>

                <label>{this.state.item.endDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>File Name:&nbsp;</label>
                </b>

                <label>{this.state.item.fileName}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Sub Product:&nbsp;</label>
                </b>

                <label>{this.state.item.subProduct}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Object Name:&nbsp;</label>
                </b>

                <label>{this.state.item.object_name}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Protocol Number (Aliases):&nbsp;</label>
                </b>
                {this.state.item.protocolIDAliases !== undefined &&
                  this.state.item.protocolIDAliases.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.protocolIDAliases)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Reason For Revision:&nbsp;</label>
                </b>

                <label>{this.state.item.jj_reason_for_revision}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>GCSR Content:&nbsp;</label>
                </b>

                <label>{this.state.item.gcsrcontent}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Valid Until:&nbsp;</label>
                </b>
                {this.state.item.maintValidUntil && (
                  <label>
                    {moment(this.state.item.maintValidUntil).format("D-MMM-YYYY")}{" "}
                  </label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Contact Email:&nbsp;</label>
                </b>

                <label>{this.state.item.contactEmail}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Generic Name:&nbsp;</label>
                </b>

                <label>{this.state.item.genericName}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Component Name:&nbsp;</label>
                </b>

                <label>{this.state.item.componentName}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Primary Compound ID:&nbsp;</label>
                </b>
                {this.state.item.primaryCompoundID !== undefined &&
                  this.state.item.primaryCompoundID.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.primaryCompoundID)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Key Takeaways:&nbsp;</label>
                </b>

                <label>{this.state.item.keyTakeaways}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Country:&nbsp;</label>
                </b>
                {this.state.item.countries !== undefined &&
                  this.state.item.countries.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.countries)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Boost Regions:&nbsp;</label>
                </b>
                {this.state.item.boostRegions !== undefined &&
                  this.state.item.boostRegions.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.boostRegions)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Promote:&nbsp;</label>
                </b>

                <label>{this.state.item.promote}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Supporting Document IDs:&nbsp;</label>
                </b>
                {this.state.item.supportingDocIDs !== undefined &&
                  this.state.item.supportingDocIDs.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.supportingDocIDs)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Full Congress Name:&nbsp;</label>
                </b>
                {this.state.item.fullCongressName !== undefined &&
                  this.state.item.fullCongressName.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.fullCongressName)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Document Type Synonyms:&nbsp;</label>
                </b>
                {this.state.item.docTypeSynonyms !== undefined &&
                  this.state.item.docTypeSynonyms.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.docTypeSynonyms)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Full CnSF:&nbsp;</label>
                </b>
                {this.state.item.fullCnSf !== undefined &&
                  this.state.item.fullCnSf.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.fullCnSf)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Regions:&nbsp;</label>
                </b>
                {this.state.item.regions !== undefined &&
                  this.state.item.regions.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.regions)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Function:&nbsp;</label>
                </b>
                {this.state.item.function !== undefined &&
                  this.state.item.function.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.function)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Primary Group:&nbsp;</label>
                </b>

                <label>{this.state.item.primary_group}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Download URL:&nbsp;</label>
                </b>

                <label>{this.state.item.downloadUrl}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Indication:&nbsp;</label>
                </b>
                {this.state.item.indication !== undefined &&
                  this.state.item.indication.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.indication)}
                    </label>
                  )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Publishing Channel:&nbsp;</label>
                </b>

                <label>{this.state.item.publishingChannel}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Response Type:&nbsp;</label>
                </b>

                <label>{this.state.item.responseType}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Formulations:&nbsp;</label>
                </b>

                <label>{this.state.item.formulations}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Sub Category TA:&nbsp;</label>
                </b>

                <label>{this.state.item.subCategoryTA}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Download URL:&nbsp;</label>
                </b>

                <label>{this.state.item.downloadUrl}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Contact Information:&nbsp;</label>
                </b>

                <label>{this.state.item.contactInformation}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Generic Product (IROs):&nbsp;</label>
                </b>

                <label>{this.state.item.genericProduct}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Product Trade Name:&nbsp;</label>
                </b>

                <label>{this.state.item.product_trade_name}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Published Date:&nbsp;</label>
                </b>

                <label>{this.state.item.publishedDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>TA (JSA):&nbsp;</label>
                </b>

                <label>{this.state.item.ta}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>SPO Web URL:&nbsp;</label>
                </b>

                <label>{this.state.item.spoWebURL}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Document Date (JSA):&nbsp;</label>
                </b>

                <label>{this.state.item.documentDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Initial Created Date:&nbsp;</label>
                </b>

                <label>{this.state.item.initialCreatedDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Sort Date:&nbsp;</label>
                </b>

                <label>{this.state.item.sortDate}</label>
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Sort Days:&nbsp;</label>
                </b>

                <label>{this.state.item.sortDays}</label>
              </div>
            </div>
            <br />
            <div
              style={{ textAlign: "left", textDecoration: "underline" }}
              className="PHM-MetaDataProperty"
            >
              <b>SEMAPHORE CLASSIFICATION (start)</b>
            </div>
            <br />
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Title Synonyms:&nbsp;</label>
                </b>
                {this.state.item.titleSynonyms !== undefined &&
                  this.state.item.titleSynonyms.length > 0 &&
                  Array.isArray(this.state.item.titleSynonyms) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.titleSynonyms)}
                  </label>
                ) : (
                  <label>{this.state.item.titleSynonyms}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Filters:&nbsp;</label>
                </b>
                {this.state.item.filters !== undefined &&
                  this.state.item.filters.length > 0 && (
                    <label>
                      {GenerateArrayStrings(this.state.item.filters)}
                    </label>
                  )}
              </div>
            </div>
            <br />
            <div
              style={{
                textAlign: "left",
                textDecoration: "underline",
                paddingBottom: "5px",
              }}
              className="PHM-MetaDataProperty"
            >
              {/* ----------------------------- */}
              <b>MAJOR TOPICS </b>
              {/* ----------------------------- */}
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Major Topics:&nbsp;</label>{" "}
                </b>
                {this.state.item.majorTopics !== undefined &&
                  this.state.item.majorTopics.length > 0 &&
                  Array.isArray(this.state.item.majorTopics) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.majorTopics)}
                  </label>
                ) : (
                  <label>{this.state.item.majorTopics}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Major Topics Synonyms:&nbsp;</label>{" "}
                </b>
                {this.state.item.majorTopicsSynonyms !== undefined &&
                  this.state.item.majorTopicsSynonyms.length > 0 &&
                  Array.isArray(this.state.item.majorTopicsSynonyms) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.majorTopicsSynonyms)}
                  </label>
                ) : (
                  <label>{this.state.item.majorTopicsSynonyms}</label>
                )}
              </div>
            </div>
            <br />
            <div
              style={{
                textAlign: "left",
                textDecoration: "underline",
                paddingBottom: "5px",
              }}
              className="PHM-MetaDataProperty"
            >
              {/* ----------------------------- */}
              <b>MINOR TOPICS </b>
              {/* ----------------------------- */}
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Minor Topics:&nbsp;</label>{" "}
                </b>
                {this.state.item.minorTopics !== undefined &&
                  this.state.item.minorTopics.length > 0 &&
                  Array.isArray(this.state.item.minorTopics) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.minorTopics)}
                  </label>
                ) : (
                  <label>{this.state.item.minorTopics}</label>
                )}
              </div>
            </div>
            <div className="PHM-MetaDataProperty">
              <div style={{ display: "block" }}>
                <b>
                  <label>Minor Topics Synonyms:&nbsp;</label>
                </b>
                {this.state.item.minorTopicsSynonyms !== undefined &&
                  this.state.item.minorTopicsSynonyms.length > 0 &&
                  Array.isArray(this.state.item.minorTopicsSynonyms) ? (
                  <label>
                    {GenerateArrayStrings(this.state.item.minorTopicsSynonyms)}
                  </label>
                ) : (
                  <label>{this.state.item.minorTopicsSynonyms}</label>
                )}
              </div>
            </div>
            <br />
            <div
              style={{ textAlign: "left", textDecoration: "underline" }}
              className="PHM-MetaDataProperty"
            >
              {/* ---------------------------------------------------------- */}
              <b>SEMAPHORE CLASSIFICATION (end)</b>
              {/* (end) ------------------------------------------------------ */}
            </div>


            <br />
          </div>
        )}
      </div>
    );
    //console.log(this.state.item);
  }
}
export default RawValuesDocumentData;
